import React, { Component } from "react";
import { shuffle } from "../../services/shuffle";
import ProgressBarStep3C2 from "../progressBar/ProgressBarStep3C2";
import BackButtonSix from "../BackButtonSix";
import { Grid } from "@material-ui/core";
import SpriteIcon from "components/baseComponents/SpriteIcon";
import NextButtonSix from "../NextButtonSix";
import { isPhone } from "services/phone";

interface ThirdProps {
  pairAnswers: any[];
  onChange(pairAnswers: any[]): void;
  moveBack(): void;
  moveNext(): void;
}

interface ThirdQuestionState {
  step: number;
  subjects: any[];
  answers: any[];
}

enum AnswerStatus {
  None,
  Correct,
  Incorrect,
}

class ThirdStepE extends Component<ThirdProps, ThirdQuestionState> {
  constructor(props: ThirdProps) {
    super(props);

    let subjects = [{
      correctIndex: 0,
      icon: "CriminologE",
      name: "Criminology"
    }, {
      correctIndex: 1,
      icon: "ForensicScienceE",
      name: "Forensic Science"
    }, {
      correctIndex: 2,
      icon: "ProductionArtsE",
      name: "Production Arts"
    }, {
      correctIndex: 3,
      icon: "eSportE",
      name: "eSports"
    }, {
      correctIndex: 4,
      icon: "UniformedServicesE",
      name: "Uniformed Services"
    },
    // just filler data 
    {
      correctIndex: 10,
      icon: "EnvironmentalScience-3c2",
      name: 'Forestry'
    }];

    subjects = shuffle(subjects);

    let answers = [{
      name: "A multi-disciplinary field which draws on psychology, sociology and statistics<br/> to evaluate criminal behaviour and the policing, justice and penal systems"
    }, {
      name: "A comprehensive multi-disciplinary course examining chemical, physical and biological<br/> techniques to acquire and analyse evidence relevant to criminal trials"
    }, {
      name: "The skills in making theatre (or film) productions happen, from costume and make-up<br/> to lighting and sound, from set design and scene painting to and creating and managing props"
    }, {
      name: "Gaming is a rapidly growing industry as are the lucrative arenas in which top players now compete.<br/> This course develops commercial and marketing and teamwork skills alongside game design and	development"
    }, {
      name: `
        This course instils qualities of leadership, fitness, teamwork under pressure and a taste for adventure.<br/>
        Students often progress to university or into the armed forces, the police, ambulance or<br/>
        fire service as well as roles in private security and border control
      `
    }];

    if (this.props.pairAnswers && this.props.pairAnswers.length > 0) {
      answers = this.props.pairAnswers;
    }


    this.state = {
      step: 0,
      subjects,
      answers
    }
  }

  renderSubjectBox(subject: any, currentAnswer: any) {
    let answerStatus = AnswerStatus.None;

    if (currentAnswer.subject) {
      if (currentAnswer.subject.correctIndex === this.state.step && currentAnswer.subject.name === subject.name) {
        answerStatus = AnswerStatus.Correct;
      } else if (currentAnswer.subject.name === subject.name) {
        answerStatus = AnswerStatus.Incorrect;
      }
    }

    return (
      <Grid item xs={6}>
        <div className={`container-3c1 font-16 ${answerStatus === AnswerStatus.Correct ? 'correct' : answerStatus === AnswerStatus.Incorrect ? 'incorrect' : ''}`} onClick={() => {
          const { answers } = this.state;
          currentAnswer.subject = subject;
          this.setState({ answers });
          this.props.onChange(answers);
        }}>
          <SpriteIcon name={subject.icon} />
          {subject.name}
          {answerStatus === AnswerStatus.Incorrect && <SpriteIcon className="absolute-svg-3c1" name="bad-answer-3c1" />}
          {answerStatus === AnswerStatus.Correct && <SpriteIcon className="absolute-svg-3c1" name="good-answer-3c1" />}
        </div>
      </Grid>
    );
  }

  render() {
    const currentAnswer = this.state.answers[this.state.step];
    if (isPhone()) {
      return (
        <div className="question-step-3e">
          <div className="relative">
            <img src="/images/choicesTool/ThirdStepR9Vaps.png" className="third-step-img" />
          </div>
          <div className="before-title-space" />
          <div className="bold font-23 question-text-3 margin-left-4">
            Vocational and<br /> Technical Subjects
          </div>
          <div className="mobile-space-2" />
          <div className="font-16 margin-left-4">
            In the sixth form, some new subjects are offered as vocational or technical qualifications.
          </div>
          <ProgressBarStep3C2 step={this.state.step} topLabel="" total={this.state.answers.length} subjectDescription={currentAnswer.name} />
          <Grid container direction="row" className="containers-3c1">
            {this.state.subjects.map(s => this.renderSubjectBox(s, currentAnswer))}
          </Grid>
          <BackButtonSix onClick={() => {
            if (this.state.step > 0) {
              this.setState({ step: this.state.step - 1 });
            } else {
              this.props.moveBack();
            }
          }} />
          <NextButtonSix label="Continue" onClick={() => {
            if (this.state.step < this.state.answers.length - 1) {
              this.setState({ step: this.state.step + 1 });
            } else {
              this.props.moveNext();
            }
          }} />
        </div>
      );
    }
    return (
      <div className="question-step-3e">
        <img src="/images/choicesTool/ThirdStepR9Vaps.png" className="third-step-img"></img>
        <div className="bold font-32 question-text-3">
          Vocational and Technical Subjects
        </div>
        <div className="font-16">
          In the sixth form, some new subjects are offered as vocational or technical qualifications.
        </div>
        <ProgressBarStep3C2 step={this.state.step} topLabel="" total={this.state.answers.length} subjectDescription={currentAnswer.name} />
        <Grid container direction="row" className="containers-3c1">
          {this.state.subjects.map(s => this.renderSubjectBox(s, currentAnswer))}
        </Grid>
        <BackButtonSix onClick={() => {
          if (this.state.step > 0) {
            this.setState({ step: this.state.step - 1 });
          } else {
            this.props.moveBack();
          }
        }} />
        <NextButtonSix label="Continue" onClick={() => {
          if (this.state.step < this.state.answers.length - 1) {
            this.setState({ step: this.state.step + 1 });
          } else {
            this.props.moveNext();
          }
        }} />
      </div>
    );
  }
}

export default ThirdStepE;
