export const BuildQuestionCache = "BuildQuestionCache";
export const ProposalCashe = "proposal";
export const CookiesPolicy = "cookies-policy";
export const PlayAssignmentId = 'play-assignment-id';
export const YoutubeClicked = 'youtube-clicked';
export const AttemptCash = 'AttemptCash';
export const PreviewAttemptCash = 'PreviewAttemptCash';
export const LoginRedirectUrl = 'LoginRedirectUrl';
export const LoginHeartOfMercia = 'LoginHeartOfMercia';
export const PlayFinishRedirectURL = 'PlayFinishRedirectURL'
export const AuthBrickCash = 'AuthBrickCash';
export const UnauthBrickCash = 'UnauthBrickCash';
export const VolumeToggle = 'VolumeToggle';
export const UserBrills = 'UserBrills';
export const Origin = 'Origin';
export const QuickAssignment = 'QuickAssignment';
export const QuickAssignmentPlay = 'QuickAssignmentPlay';
export const LoginUrl = 'LoginUrl';
export const ActivateUrl = 'ActivateUrl';
export const LastAttemptId = 'LastAttemptId';

export const AdminDashboardBricksFilters = 'AdminDashboardBricksFilters';
export const AssigningClassrooms = 'AssigningClassrooms';
export const SidebarAssignmentsSort = 'SidebarAssignmentsSort';
export const SidebarClassroomSort = 'SidebarClassroomSort';
export const ClassWithNewAssignments = 'ClassWithNewAssignments';
