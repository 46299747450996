import { SixthformAuth } from "components/map";

export const EmailSignUp = SixthformAuth + '/signup-email';
export const SignUp = SixthformAuth + '/signup';
export const SignIn = SixthformAuth + '/signin';

export default {
  SignUp,
  SignIn,
  EmailSignUp,
}
