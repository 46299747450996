import React, { Component } from "react";
import { SixthformSubject } from "services/axios/sixthformChoices";
import BackButtonSix from "../BackButtonSix";
import SpriteIcon from "components/baseComponents/SpriteIcon";
import { Dialog } from "@material-ui/core";
import NextButtonSix from "../NextButtonSix";
import { isPhone } from "services/phone";

export enum ThirdStepDSubStep {
  Start = 1,
  Message,
  TableLeaf,
  LastStep
}

interface ThirdProps {
  subjects: SixthformSubject[];
  answer: any;
  moveBack(answer: any): void;
  moveNext(answer: any): void;
}

interface TLevelCourse {
  icon: string;
  name: string;
  expanded?: boolean;
  subjects: any[];
}

interface ThirdQuestionState {
  tLevelCoursesPart1: TLevelCourse[];
  tLevelCoursesPart2: TLevelCourse[];
  overflowOpen: boolean;
}

class ThirdStepF extends Component<ThirdProps, ThirdQuestionState> {
  constructor(props: ThirdProps) {
    super(props);

    let tLevelCoursesPart1 = [{
      icon: 'six-frame12',
      name: 'Maths & English',
      subjects: [
        { name: 'Applied Maths (Core & Certificate)' },
        { name: 'ESOL (English for Speakers of Other Languages)' }
      ],
    }, {
      icon: 'six-frame9',
      name: 'Engineering & Maintenance',
      subjects: [
        { name: 'Engineering' },
        { name: 'Mechanical Engineering' },
        { name: 'Electrical & Electronic Engineering' },
        { name: 'Manufacturing Engineering' },
        { name: 'Motor Vehicle Maintenance' },
      ],
    }, {
      icon: 'six-frame6',
      name: 'Business',
      subjects: [
        { name: 'Applied Business' },
        { name: 'Marketing' },
        { name: 'Enterprise & Entrepreneurship' },
        { name: 'Accounting & Bookkeeping' },
      ],
    }, {
      icon: 'six-frame13',
      name: 'Music & Performing Arts',
      subjects: [
        { name: 'Digital Music Production' },
        { name: 'Dance' },
        { name: 'Performing Arts (Acting)' },
        { name: 'Musical Theatre' },
        { name: 'Music Performance' },
        { name: 'Music' },
        { name: 'Production Arts' },
      ],
    }, {
      icon: 'six-frame8',
      name: 'IT, Digital & Media',
      subjects: [
        { name: 'Information Technology' },
        { name: 'Creative Media / Digital Media Production' },
        { name: 'Computing' },
        { name: 'eSports & Computer Games' },
      ],
    }, {
      icon: 'six-frame7',
      name: 'Built Environment',
      subjects: [
        { name: 'Construction & Built Environment' },
        { name: 'Electrical Installation' },
        { name: 'Plumbing' },
        { name: 'Brickwork' },
        { name: 'Plastering' },
        { name: 'Carpentry' },
      ]
    }, {
      icon: 'six-frame13',
      name: 'Hospitality & Catering',
      subjects: [
        { name: 'Culinary Arts' },
      ]
    }]
    let tLevelCoursesPart2 = [{
      icon: 'six-frame19',
      name: 'Science & Engineering',
      subjects: [
        { name: 'Food Science & Nutrition' },
        { name: 'Applied Psychology' },
        { name: 'Medical Science' },
        { name: 'Applied Human Biology' },
        { name: 'Applied Science' },
        { name: 'Forensic Science' },
      ],
    }, {
      icon: 'six-frame18',
      name: 'Law, Crime & Uniformed Services',
      subjects: [
        { name: 'Criminology' },
        { name: 'Uniformed Services' },
        { name: 'Applied Law' },
        { name: 'Pre-cadetship for Merchant Navy' },
      ],
    }, {
      icon: 'six-frame17',
      name: 'Care',
      subjects: [
        { name: 'Childcare & Child Development (Education & Early Years)' },
        { name: 'Health & Social Care' }
      ],
    }, {
      icon: 'six-frame2',
      name: 'Art & Design',
      subjects: [
        { name: 'Art & Design (Photography)' },
        { name: 'Art & Design' },
      ]
    }, {
      icon: 'six-frame16',
      name: 'Sport, Leisure & Wellbeing',
      subjects: [
        { name: 'Sport & Exercise Science' },
        { name: 'Beauty' },
        { name: 'Sporting Excellence' },
        { name: 'Hairdressing' },
        { name: 'Personal Training & Instruction' },
        { name: 'Travel & Tourism' },
      ]
    }, {
      icon: 'six-frame15',
      name: 'Land & Animal Management',
      subjects: [
        { name: 'Agriculture' },
        { name: 'Land & Countryside Management' },
        { name: 'Horticulture' },
        { name: 'Animal Care & Management' },
        { name: 'Forestry' },
        { name: 'Sustainability' },
      ]
    }];

    if (
      props.answer && props.answer.tLevelCoursesPart1 && props.answer.tLevelCoursesPart1.length > 0 &&
      props.answer.tLevelCoursesPart2 && props.answer.tLevelCoursesPart2.length > 0
    ) {
      tLevelCoursesPart1 = props.answer.tLevelCoursesPart1;
      tLevelCoursesPart2 = props.answer.tLevelCoursesPart2;
    }

    this.state = {
      tLevelCoursesPart1,
      tLevelCoursesPart2,
      overflowOpen: false,
    }
  }

  addSelectedSubject(selected: any[], courses: TLevelCourse[]) {
    for (let course of courses) {
      for (let subject of course.subjects) {
        if (subject.checked) {
          selected.push(subject);
        }
      }
    }
  }

  renderList(className: string, list: TLevelCourse[], onChange: Function) {
    return (
      <div>
        {list.map((course, i) => {
          return (
            <div key={i} className={"course-box-r-23 " + className}>
              <div className="font-16 bold flex" onClick={() => {
                const checked = course.subjects.find(s => s.checked);

                if (!course.expanded) {
                  course.expanded = !course.expanded;
                  onChange();
                } else if (!checked) {
                  course.expanded = !course.expanded;
                  onChange();
                }

                this.setState({ tLevelCoursesPart1: [...this.state.tLevelCoursesPart1] });
              }}>
                <div className="flex-center big-r-23">
                  <SpriteIcon name={course.icon} />
                </div>
                <div className="flex-y-center course-name-r23">
                  {course.name}
                </div>
                <div className="flex-center coursor-pointer">
                  <SpriteIcon name={course.expanded ? "arrow-up" : "arrow-down"} />
                </div>
              </div>
              {course.expanded && <div className="course-subjects-r23">
                {course.subjects.map((subject, i) =>
                  <div className="course-subject-r23" onClick={() => {
                    if (!subject.checked) {
                      let selected: any[] = [];
                      this.addSelectedSubject(selected, this.state.tLevelCoursesPart1);
                      this.addSelectedSubject(selected, this.state.tLevelCoursesPart2);
                      if (selected.length < 5) {
                        subject.checked = true;
                      } else {
                        this.setState({ overflowOpen: true });
                      }
                    } else {
                      subject.checked = false;
                    }
                    onChange();
                  }}>
                    {!isPhone() &&
                      <div className="flex-center">
                        <SpriteIcon name={subject.checked ? 'radio-btn-active' : 'radio-btn-blue'} />
                      </div>}
                    <div className={`flex-y-center text-container-r23 font-15 ${subject.checked ? 'active' : ''}`}>
                      {subject.name}
                    </div>
                  </div>)}
              </div>}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    if (isPhone()) {
      return (
        <div className="font-16 question-step-3f">
          <div className="relative">
            <img src="/images/choicesTool/ThirdStepR10.png" className="third-step-img" />
          </div>
          <div className="bold font-23 question-text-3 margin-left-4">
            Vocational and Technical<br /> Subjects
          </div>
          <div className="font-16 margin-left-4">
            Click on any of the categories below to reveal more<br />
            than fifty course options. Select any that you would<br /> be interested in studying.
          </div>
          <div className="d3-table-scroll-container">
            <div className="d3-table-leaf">
              {this.renderList("first-b-r-23", this.state.tLevelCoursesPart1, () => {
                this.setState({ tLevelCoursesPart1: [...this.state.tLevelCoursesPart1] })
              })}
              {this.renderList("second-b-r-23", this.state.tLevelCoursesPart2, () => {
                this.setState({ tLevelCoursesPart2: [...this.state.tLevelCoursesPart2] })
              })}
            </div>
          </div>
          <BackButtonSix onClick={() => {
            this.props.moveBack({
              tLevelCoursesPart1: this.state.tLevelCoursesPart1,
              tLevelCoursesPart2: this.state.tLevelCoursesPart2
            })
          }} />
          {this.state.overflowOpen && <Dialog className='too-many-dialog' open={true} onClose={() => this.setState({ overflowOpen: false })}>
            You can’t select more than five courses.<br /> Deselect another course before selecting a new one.
            <div className="btn" onClick={() => this.setState({ overflowOpen: false })}>Close</div>
          </Dialog>}
          <NextButtonSix label="Continue" onClick={() => {
            this.props.moveNext({
              tLevelCoursesPart1: this.state.tLevelCoursesPart1,
              tLevelCoursesPart2: this.state.tLevelCoursesPart2
            });
          }} />
        </div>
      );
    }
    return (
      <div className="font-16 question-step-3f">
        <img src="/images/choicesTool/ThirdStepR10.png" className="third-step-img"></img>
        <div className="bold font-32 question-text-3">
          Vocational and Technical Subjects
        </div>
        <div className="font-16">
          Click on any of the categories below to reveal more than fifty course options. Select any that you would be interested in studying.
        </div>
        <div className="d3-table-scroll-container">
          <div className="d3-table-leaf">
            {this.renderList("first-b-r-23", this.state.tLevelCoursesPart1, () => {
              this.setState({ tLevelCoursesPart1: [...this.state.tLevelCoursesPart1] })
            })}
            {this.renderList("second-b-r-23", this.state.tLevelCoursesPart2, () => {
              this.setState({ tLevelCoursesPart2: [...this.state.tLevelCoursesPart2] })
            })}
          </div>
        </div>
        <BackButtonSix onClick={() => {
          this.props.moveBack({
            tLevelCoursesPart1: this.state.tLevelCoursesPart1,
            tLevelCoursesPart2: this.state.tLevelCoursesPart2
          })
        }} />
        {this.state.overflowOpen && <Dialog className='too-many-dialog' open={true} onClose={() => this.setState({ overflowOpen: false })}>
          You can’t select more than five courses.<br /> Deselect another course before selecting a new one.
          <div className="btn" onClick={() => this.setState({ overflowOpen: false })}>Close</div>
        </Dialog>}
        <NextButtonSix label="Continue" onClick={() => {
          this.props.moveNext({
            tLevelCoursesPart1: this.state.tLevelCoursesPart1,
            tLevelCoursesPart2: this.state.tLevelCoursesPart2
          });
        }} />
      </div>
    );
  }
}

export default ThirdStepF;
