
import React, { Component } from "react";

import BackButtonSix from "../BackButtonSix";
import SpriteIcon from "components/baseComponents/SpriteIcon";
import { Grid } from "@material-ui/core";
import ProgressBarR2 from "../progressBar/ProgressBarR2";
import NextButtonSix from "../NextButtonSix";
import { isPhone } from "services/phone";

interface ThirdProps {
  careers: any;
  onChange(answer: any): void;
  moveBack(answer: any): void;
  moveNext(answer: any): void;
}

enum AnswerStatus {
  None,
  Correct,
  Incorrect,
}

interface ThirdQuestionState {
  step: number;
  answers: any[];
  careers: any[];
}

class FifthStepA extends Component<ThirdProps, ThirdQuestionState> {
  constructor(props: ThirdProps) {
    super(props);

    let answers = [{
      correctIndex: 0,
      name: "Scientific Disciplines",
      subName: "(e.g. medicine or engineering)"
    }, {
      correctIndex: 1,
      name: "Building Trades, Skilled Industry & Manufacturing",
      subName: "(e.g. electrician, automotive maintenance)"
    }, {
      correctIndex: 2,
      name: "Discursive, Managerial and Strategic",
      subName: "(e.g. law, media, advertising, HR, civil service)"
    }, {
      correctIndex: 3,
      name: "Creative, Performative & Craft",
      subName: "(e.g. photography, fashion design)"
    }, {
      correctIndex: 4,
      name: "Commercial Disciplines",
      subName: "(e.g. accountancy, insurance, finance, share trading)"
    }, {
      correctIndex: 5,
      name: "Secondary Teaching and Academia",
      subName: "(secondary school and university posts)"
    }, {
      correctIndex: 6,
      name: "Skilled roles in health, social care, public service",
      subName: "(e.g. nursing, social work, uniformed services)"
    }];

    let careers = [{
      name: "almost invariably require Maths & Science A-levels, usually to a high standard"
    }, {
      name: "require vocational qualifications and, often, apprenticeships"
    }, {
      name: "often follow from prestigious academic degrees well-disposed to A-levels in ‘essay subjects’"
    }, {
      name: "expect training in specific artistic, technical and digital skills (e.g. drawing, dance, CAD )"
    }, {
      name: "strongly favour Maths and well-disposed towards Business, Economics etc."
    }, {
      name: "high proficiency in subject specialisms and post-graduate training or additional degrees"
    }, {
      name: "require vocational training but recruit from a wide range of post-16 and degree courses"
    }];

    if (this.props.careers && this.props.careers.length > 0) {
      careers = this.props.careers;
    }

    this.state = {
      step: 0,
      answers,
      careers
    }
  }

  getAnswer() {
    return this.state.careers
  }

  renderSubjectBox(subject: any, currentAnswer: any) {
    let answerStatus = AnswerStatus.None;

    if (currentAnswer.subject) {
      if (currentAnswer.subject.correctIndex === this.state.step && currentAnswer.subject.name === subject.name) {
        answerStatus = AnswerStatus.Correct;
      } else if (currentAnswer.subject.name === subject.name) {
        answerStatus = AnswerStatus.Incorrect;
      }
    }

    return (
      <Grid item xs={6}>
        <div className={`container-3c1 font-16 ${answerStatus === AnswerStatus.Correct ? 'correct' : answerStatus === AnswerStatus.Incorrect ? 'incorrect' : ''}`} onClick={() => {
          const { careers } = this.state;
          currentAnswer.subject = subject;
          this.setState({ careers });
          this.props.onChange(careers);
        }}>
          <SpriteIcon name={subject.icon} />
          <div className="text-center">
            <div className="font-16 bold">{subject.name}</div>
            <div className="font-13">{subject.subName}</div>
          </div>
          {answerStatus === AnswerStatus.Incorrect && <SpriteIcon className="absolute-svg-3c1" name="bad-answer-3c1" />}
          {answerStatus === AnswerStatus.Correct && <SpriteIcon className="absolute-svg-3c1" name="good-answer-3c1" />}
        </div>
      </Grid>
    );
  }

  render() {
    const currentAnswer = this.state.careers[this.state.step];
    if (isPhone()) {
      return (
        <div className="question-career-container-5a">
          <div className="question question-step-5a question-career-5a">
            <div className="relative">
              <img src="/images/choicesTool/phone/Career1.png" className="third-step-img fifth-step-img-r13" />
            </div>
            <div className="bold question-text-4 font-23 margin-left-4">
              Categories of Career
            </div>
            <div className="font-16 margin-left-4">
              For many careers, you can study <br /> almost any combination of subjects in <br /> sixth form.
            </div>
            <div className="between-texts-er3434" />
            <div className="font-16 margin-left-4">
              However, a number of professions have specific <br /> expectations for post-16 education.
            </div>
            <div className="between-texts-er3434" />
            <div className="font-16 margin-left-4">
              Match the statement with the professional category.
            </div>
            <div className="question-step-5a categories-of-carreer margin-left-4">
              <ProgressBarR2 step={this.state.step} total={this.state.careers.length} description={currentAnswer.name} />
              {this.state.answers.map(s => this.renderSubjectBox(s, currentAnswer))}
            </div>
            <BackButtonSix onClick={() => {
                if (this.state.step <= 0) {
                  this.props.moveBack(this.getAnswer());
                } else {
                  this.setState({ step: this.state.step - 1 });
                }
              }} />
              <NextButtonSix label="Continue" onClick={() => {
                if (this.state.step >= this.state.careers.length - 1) {
                  this.props.moveNext(this.getAnswer());
                } else {
                  this.setState({ step: this.state.step + 1 });
                }
              }} />
          </div>
        </div>
      )
    }
    return (
      <div className="question question-step-5a question-career-5a">
        <img src="/images/choicesTool/FifthStepR13.png" className="third-step-img fifth-step-img-r13" />
        <div className="bold question-text-4 font-32">
          Categories of Career
        </div>
        <div className="font-16">
          For many careers, you can study almost any combination of subjects in sixth form.
        </div>
        <div className="font-16">
          However, a number of professions have specific expectations for post-16 education.
        </div>
        <div className="font-16">
          Match the statement with the professional category.
        </div>
        <div className="question-step-5a categories-of-carreer">
          <ProgressBarR2 step={this.state.step} total={this.state.careers.length} description={currentAnswer.name} />
          <Grid container direction="row" className="containers-3c1">
            {this.state.answers.map(s => this.renderSubjectBox(s, currentAnswer))}
          </Grid>
          <BackButtonSix onClick={() => {
            if (this.state.step <= 0) {
              this.props.moveBack(this.getAnswer());
            } else {
              this.setState({ step: this.state.step - 1 });
            }
          }} />
          <NextButtonSix label="Continue" onClick={() => {
            if (this.state.step >= this.state.careers.length - 1) {
              this.props.moveNext(this.getAnswer());
            } else {
              this.setState({ step: this.state.step + 1 });
            }
          }} />
        </div>
      </div>
    );
  }
}

export default FifthStepA;
